.bill-main{
  height: calc(100vh -  110px);
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: -30px;
  margin-right: -30px;
  .bill-sider{
    .bill-sider-dept{
      height: 140px;
      overflow: hidden;
    }
  }

  .bill-left{
    overflow: hidden;
    height: calc(100vh -  110px);
    position: relative;
  }
  .bill-left-con{
    padding: 15px;
    margin-left: 30px;
    background: #ffffff;
  }
  .bill-right{
    overflow: hidden;
    height: calc(100vh -  110px);
    position: relative;
  }

  .bill-right-con{
    padding: 30px;
    margin-left: 0px;
    margin-right: 30px;
    background: #ffffff;
    min-height:  calc(100vh -  170px);
  }
  .statistics-box{

  }

  .bill-btn-group {
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 30;
    background: #ffffff;
    right:30px;
    height: 70px;
    justify-content: center;
    align-items: center;
    .u-btn {
      width: 96px;
    }
    .s-cancel {
      background-color: #ffffff;
      color: #ACACAC;
      border-color: #acacac;
    }
  }
}


.bill-sider-calendar{
  height: calc(100vh -  278px);
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}

.audit .bill-sider-calendar{
  height: calc(100vh -  134px);
  overflow: hidden;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 24px;
}
.calendar-yeadr-picker{
  margin: 0 auto;
  position: relative;
  text-align: center;
  height: 32px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3eb784;
  font-size: 16px;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;

  .arrow-l{
    position: absolute;
    left: 36px;
    top: 8px;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("~assets/img/arrow-l.png") no-repeat center;
    background-size: cover;

  }
  .arrow-r{
    position: absolute;
    right: 36px;
    top: 8px;
    width: 16px;
    height: 16px;
    display: inline-block;
    background: url("~assets/img/arrow-r.png") no-repeat center;
    background-size: cover;

  }
}
.calendar{
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}
.calendar-item{
  width: 54px;
  height: 54px;
  white-space: nowrap;
  background: #fafafa;
  color: #b6b6b6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  border: solid 2px transparent;
  cursor: pointer;
  overflow: hidden;

  &.cur,&.auditd{
    background: #d6ffed;
    color: #3eb784;
    font-size: 14px;
    border: solid 2px #3eb784;
  }
  &.unfinish::after{
    content: '缺';
    font-size: 14px;
    padding: 2px;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: 0;
    background: #fe4a4d;
    color: #ffffff;
  }
  &.unaudit::after{
    content: '审';
    font-size: 14px;
    padding: 2px;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: 0;
    background: #fe4a4d;
    color: #ffffff;
  }

  .mask{
    position: absolute; width: 100%; height: 100%; background: rgba(0,0,0,0.1); top: 0; left:0;
  }
}

.table-box{
  th.el-table__cell{
    padding:12px 0 !important;
    background: #fafafa;
  }
  td.el-table__cell{
    padding:8px 0 !important
  }
}

.table-box-test{

}
.table-box-test-item{
  display: block;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;

  .table-box-test-item-label{
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    margin-right: 10px;
  }
}

.bill-tab{
  .el-tabs__item span::after{
    content: '缺';
    font-size: 12px;
    padding: 2px;
    border-radius: 4px;
    position: absolute;
    right: -15px;
    top: 0;
    background: #fe4a4d;
    color: #ffffff;
    line-height: 1;
    text-align: center;
  }
  .el-tabs__item span.chanliang::after {
    right: 0;
  }
  .el-tabs__item span.isFill::after{
    display: none;
  }
}


.bill-statistics-box{
  height: 90px;
  display: flex;
  align-items: center;

  .statistics-item{
    width: 180px;
    height: 90px;
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
    .label{
      font-size: 30px;
      margin-bottom: 10px;
    }

    &.item1{
      background: url("~assets/img/bill/item1.png") no-repeat center;
      background-size: cover;
    }

    &.item2{
      width: 180px;
      height: 90px;
      background: url("~assets/img/bill/item2.png") no-repeat center;
      background-size: cover;
    }
  }
}


.table-box{

  .el-table .cell{
    line-height: 32px;
  }
}

$publicPath:'';

.shenhe-table-box {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
